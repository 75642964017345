// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/platform-adapter.ts"
);
import.meta.hot.lastModified = "1734124776000";
}
// REMIX HMR END

export const IS_CF_PAGES = typeof process === 'undefined';
